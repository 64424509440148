const navigationContainer = document.querySelector('.nav-container') as HTMLElement | null;
const mainNavigation = document.querySelector('.main-navigation') as HTMLElement | null;
const parentItems = document.querySelectorAll('.main-navigation button');
const sections = document.querySelectorAll('.section');
const siteMenu = document.querySelector('.site-menu') as HTMLElement | null;
const arrow = document.querySelector('.site-arrow') as HTMLElement | null;

const resizeObserver = new ResizeObserver(() => {
    calculateSectionDimensions(sections);
});

if (navigationContainer) {
    resizeObserver.observe(navigationContainer);
}

let sectionWidths: Array<number> = [];
let sectionHeights: Array<number> = [];

window.addEventListener('load', function onWindowLoad() {
    calculateSectionDimensions(sections);
    calculateSuperNavAccountMenuWrapperWidth();
});

window.addEventListener('resize', function onWindowResize() {
    calculateSuperNavAccountMenuWrapperWidth();
});

sections.forEach((section) => {
    const images = section.querySelectorAll('img');
    images.forEach((image) => {
        image.addEventListener('load', () => {
            calculateSectionDimensions(sections);
        });
    });
});

function calculateSectionDimensions(sections: NodeListOf<Element>) {
    if (!sections.length) {
        return;
    }

    sectionWidths = [];
    sectionHeights = [];

    sections.forEach((section) => {
        const elem = section as HTMLElement;
        sectionWidths.push(elem.clientWidth);
        sectionHeights.push(elem.clientHeight);
        if (section.getAttribute('hidden') === null) {
            siteMenu?.style.setProperty('--site-menu-width', `${sectionWidths.at(-1)}px`);
            siteMenu?.style.setProperty('--site-menu-height', `${sectionHeights.at(-1)}px`);
        }
    });
}

if (mainNavigation) {
    mainNavigation.addEventListener('mouseover', (event) => {
        const targetElement = event.target as Element;
        const allButtons = mainNavigation.querySelectorAll('button');

        if ('BUTTON' === targetElement.tagName && allButtons.length) {
            let index = 0;

            allButtons.forEach((elem, idx) => {
                if (elem === targetElement) {
                    index = idx;
                }
            });

            if (siteMenu && arrow) {
                showNavigation(targetElement as HTMLButtonElement, index, siteMenu, arrow);
            }
        } else {
            hideNavigation();
        }
    });

    mainNavigation.addEventListener('click', (event) => {
        const targetElement = event.target as Element;

        if ('BUTTON' === targetElement.tagName && navigationContainer) {
            if (navigationContainer.classList.contains('open')) {
                hideNavigation();
            } else {
                let index = 0;

                const allButtons = mainNavigation.querySelectorAll('button');
                allButtons.forEach((elem, idx) => {
                    if (elem === targetElement) {
                        index = idx;
                    }
                });

                if (siteMenu && arrow) {
                    showNavigation(targetElement as HTMLButtonElement, index, siteMenu, arrow);
                }
            }
        }
    });
}

if (navigationContainer) {
    navigationContainer.addEventListener('mouseleave', () => {
        hideNavigation();
    });
}

function showNavigation(button: HTMLButtonElement, index: number, menu: HTMLElement, arrow: HTMLElement): void {
    const sectionTarget: string = button.dataset.target || '';

    if (!sectionTarget) {
        return;
    }

    const sectionElement = document.querySelector(`#${sectionTarget}`);

    const sectionWidth = sectionWidths[index];
    const sectionHeight = sectionHeights[index];

    /* Root Nav Container */
    if (navigationContainer) {
        navigationContainer.classList.add('open');
    }

    /* Button navigation */
    parentItems.forEach((button) => {
        button.classList.remove('open');
    });

    button.classList.add('open');

    /* Sections */
    sections.forEach((section) => {
        section.setAttribute('hidden', '');
    });

    if (sectionElement) {
        sectionElement.removeAttribute('hidden');
    }

    /* Setup menu */
    const buttonPosX = button.getBoundingClientRect().left;
    let posX = -1 * ((window.innerWidth - sectionWidth) / 2 - buttonPosX + sectionWidth / 2);

    const sectionOccupiedWidth = buttonPosX + sectionWidth;

    /**
     * If section occupied width space is larger
     * than the current window width, the section is
     * going to overflow. Let's align the section from
     * the right side instead.
     */
    if (sectionOccupiedWidth > window.innerWidth) {
        posX = -1 * (window.innerWidth / 2 + sectionWidth - button.getBoundingClientRect().right);
    }

    menu.style.setProperty('--site-menu-offset', `${posX}px`);
    menu.style.setProperty('--site-menu-width', `${sectionWidth}px`);
    menu.style.setProperty('--site-menu-height', `${sectionHeight}px`);

    /* Setup arrow */
    if (arrow) {
        const absolutePosition = buttonPosX + button.clientWidth / 2;
        const arrowPosX = -1 * (window.innerWidth / 2 - absolutePosition);

        arrow.style.transform = `translateY(-12px) translateX(${arrowPosX}px) rotate(45deg)`;
    }
}

function hideNavigation(): void {
    if (navigationContainer && navigationContainer.classList.contains('open')) {
        navigationContainer.classList.remove('open');
    }

    if (parentItems.length) {
        parentItems.forEach((button) => {
            if (button.classList.contains('open')) {
                button.classList.remove('open');
            }
        });
    }

    sections.forEach((section) => {
        section.setAttribute('hidden', '');
    });
}

function calculateSuperNavAccountMenuWrapperWidth(): void {
    const superNavAccountMenuContainer = document.querySelector('#super-nav-account-menu') as HTMLElement | null;

    if (!superNavAccountMenuContainer) {
        return;
    }

    const body = document.querySelector('body') as HTMLBodyElement;

    function getWidthInCssPixels(superNavContainerElement: HTMLElement): string {
        return `${superNavContainerElement.clientWidth}px`;
    }

    body.style.setProperty('--super-nav-width', getWidthInCssPixels(superNavAccountMenuContainer));
}
